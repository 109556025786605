import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ApplicationsDeploymentTag } from "../domain/ApplicationsDeploymentTag";
import { bumpStagingVersion } from "../services/bumpStagingVersion";
import { DeploymentEnvironments } from "../domain/DeploymentEnvironments";
import { buildProductionVersion } from "../services/buildProductionVersion";
import { ArrowRightAlt } from "@mui/icons-material";
import { PrimaryButton } from "../../core/components/PrimaryButton";

interface Props {
    selectedApplications: ApplicationsDeploymentTag[];
    environment: DeploymentEnvironments;
    onDeploy: () => void;
}

export function ReviewDeploymentStep({ selectedApplications, environment, onDeploy }: Props) {
    const updateVersion = environment === DeploymentEnvironments.STAGING ? bumpStagingVersion : buildProductionVersion;

    return (
        <Box
            data-testid="step.reviewTags"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
            }}
        >
            {selectedApplications.map((application) => (
                <Box key={application.name} sx={{ display: "flex" }}>
                    <Typography sx={{ width: 150 }}>{application.name}</Typography>
                    <Box sx={{ flex: 1, display: "flex", gap: 1 }}>
                        <Typography>{application.version}</Typography>
                        <ArrowRightAlt color="primary" />
                        <Typography>{updateVersion(application.version)}</Typography>
                    </Box>
                </Box>
            ))}
            <PrimaryButton onClick={onDeploy} sx={{ marginTop: 2 }} data-testid="button.deploy">
                Deploy
            </PrimaryButton>
        </Box>
    );
}
