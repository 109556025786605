import { useQuery } from "react-query";
import axios, { AxiosResponse } from "axios";
import { API_BASE_URL } from "../../config";
import { ApplicationsVersion } from "../domain/ApplicationsDeploymentTag";

export const useApplicationsVersion = () => {
    const { data } = useQuery<ApplicationsVersion>("applicationVersions", async () =>
        axios
            .get(`${API_BASE_URL}/versions`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
            })
            .then(({ data }: AxiosResponse<ApplicationsVersion>) => data)
    );

    return data ?? { production: [], staging: [] };
};
