import { MongoChart } from "../core/mongo/MongoChart";

export function ZipCamGeolocationChart() {
    return (
        <MongoChart
            baseUrl="https://charts.mongodb.com/charts-canex-prod-staging-dev-mndwt"
            chartId="657381a9-d993-4b3e-870d-b3ec49a574ab"
        />
    );
}
