import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Dashboard } from "../../dashboard/components/Dashboard";
import { FieldDashboard } from "../../fieldDashboard/FieldDashboard";
import { LoginPage } from "../../login/components/LoginPage";
import { AppContainer } from "../AppContainer";
import { PATHS } from "./path";
import { ZipCamDashboard } from "../../zipcamDashboard/ZipCamDashboard";

export function AppRouter() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={PATHS.login} element={<LoginPage />} />
                <Route path={"/*"} element={<AppContainer />}>
                    <Route path={PATHS.home} element={<Dashboard />} />
                    <Route path={PATHS.dashboard} element={<Dashboard />} />
                    <Route path={PATHS.fieldDashboard} element={<FieldDashboard />} />
                    <Route path={PATHS.zipcamDashboard} element={<ZipCamDashboard />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
