import { NewDeploymentStepper } from "../../deployments/components/NewDeploymentStepper";
import { FieldDailySyncAttemptsChart } from "../../fieldDashboard/FieldDailySyncAttemptsChart";
import { FieldRecentExceptionChart } from "../../fieldDashboard/FieldRecentExceptionChart";
import { FieldStatusPerVersionChart } from "../../fieldDashboard/FieldStatusPerVersionChart";
import { FieldTotalSyncAttemptsChart } from "../../fieldDashboard/FieldTotalSyncAttemptsChart";
import { FieldUserChart } from "../../fieldDashboard/FieldUserChart";
import { PullRequestsTile } from "../../pullRequests/components/PullRequestsTile";
import { EmptyTile } from "./components/EmptyTile";
import { Calendar } from "../../calendar/components/Calendar";
import { Tile } from "./Tile";
import { Objectives } from "../../objectives/Objectives";
import { ZipCamTotalPushAttemptsChart } from "../../zipcamDashboard/ZipCamTotalPushAttemptsChart";
import { ZipCamDailyPushAttemptsChart } from "../../zipcamDashboard/ZipCamDailyPushAttemptsChart";
import { ZipCamGeolocationChart } from "../../zipcamDashboard/ZipCamGeolocationChart";
import { ZipCamRecentExceptionChart } from "../../zipcamDashboard/ZipCamRecentExceptionChart";

export const TILE_MAP: Record<Tile, React.ReactNode> = {
    [Tile.ACTIVE_USERS]: <div>activeUsers</div>,
    [Tile.BACKLOG]: <div>backlog</div>,
    [Tile.CALENDAR]: <Calendar />,
    [Tile.DEPLOYMENT]: <NewDeploymentStepper />,
    [Tile.EMPTY]: <EmptyTile />,
    [Tile.FIELD_DAILY_SYNC_ATTEMPTS_CHART]: <FieldDailySyncAttemptsChart />,
    [Tile.FIELD_RECENT_EXCEPTION_CHART]: <FieldRecentExceptionChart />,
    [Tile.FIELD_STATUS_PER_VERSION_CHART]: <FieldStatusPerVersionChart />,
    [Tile.FIELD_TOTAL_SYNC_ATTEMPTS_CHART]: <FieldTotalSyncAttemptsChart />,
    [Tile.FIELD_USER_CHART]: <FieldUserChart />,
    [Tile.ZIPCAM_TOTAL_PUSH_ATTEMPTS_CHART]: <ZipCamTotalPushAttemptsChart />,
    [Tile.ZIPCAM_DAILY_PUSH_ATTEMPTS_CHART]: <ZipCamDailyPushAttemptsChart />,
    [Tile.ZIPCAM_GEOLOCATION_CHART]: <ZipCamGeolocationChart />,
    [Tile.ZIPCAM_RECENT_EXCEPTION_CHART]: <ZipCamRecentExceptionChart />,
    [Tile.OBJECTIVES]: <Objectives />,
    [Tile.PULL_REQUESTS]: <PullRequestsTile />,
    [Tile.SPRINTS]: <div>sprints</div>,
    [Tile.VERSIONS]: <div>versions</div>,
};
