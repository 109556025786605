import { Box, Button, Card, styled, TextField } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { useState } from "react";
import * as config from "../../config";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../core/navigation/path";

const Container = styled("div")({
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
});

const FormContainer = styled("div")(({ theme }) => ({
    width: "50%",
    [theme.breakpoints.only("xs")]: {
        width: "100%",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));



const FormContent = styled(Card)(({ theme }) => ({
    display: "flex",
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    flexDirection: "column",
    textAlign: "center",
    gap: theme.spacing(1),
}));



export function LoginPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    function handleSubmit(event: any) {
        event.preventDefault();

        axios
            .post(`${config.API_BASE_URL}/auth/login`, { username: username, password: password })
            .then((response: AxiosResponse<any>) => {
                localStorage.setItem("accessToken", response.data.access_token);
                navigate(PATHS.dashboard);
            });
    }

    return (
        <Container sx={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + "/logincover.jpg"})`,
                    backgroundSize: "cover",
                    backgroundPosition: "right",
                }}>
            <FormContainer>
                <form className="form" onSubmit={handleSubmit}>
                    <FormContent variant="outlined">
                        <h1>Canex Life</h1>
                        <TextField
                            autoFocus
                            label="Username"
                            variant="outlined"
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                        <TextField
                            label="Password"
                            variant="outlined"
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <Box sx={{ paddingY: 2 }}>
                            <Button variant="contained" type="submit" color="primary">
                                Log in
                            </Button>
                        </Box>
                    </FormContent>
                </form>
            </FormContainer>
        </Container>
    );
}
