import axios from "axios";
import { API_BASE_URL } from "../../config";
import { ApplicationsDeploymentTag } from "../domain/ApplicationsDeploymentTag";

export function deployStaging(applications: ApplicationsDeploymentTag[]) {
    return axios.post(
        `${API_BASE_URL}/deploy/staging`,
        { data: { applications } },
        {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
    );
}
export function deployProduction(applications: ApplicationsDeploymentTag[]) {
    return axios.post(
        `${API_BASE_URL}/deploy/production`,
        { data: { applications } },
        {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
    );
}
