export enum Tile {
    ACTIVE_USERS = "ACTIVE_USERS",
    BACKLOG = "BACKLOG",
    CALENDAR = "CALENDAR",
    DEPLOYMENT = "DEPLOYMENT",
    EMPTY = "EMPTY",
    FIELD_DAILY_SYNC_ATTEMPTS_CHART = "FIELD_DAILY_SYNC_ATTEMPTS_CHART",
    FIELD_RECENT_EXCEPTION_CHART = "FIELD_RECENT_EXCEPTION_CHART",
    FIELD_STATUS_PER_VERSION_CHART = "FIELD_STATUS_PER_VERSION_CHART",
    FIELD_TOTAL_SYNC_ATTEMPTS_CHART = "FIELD_TOTAL_SYNC_ATTEMPTS_CHART",
    FIELD_USER_CHART = "FIELD_USER_CHART",
    ZIPCAM_TOTAL_PUSH_ATTEMPTS_CHART = "ZIPCAM_TOTAL_PUSH_ATTEMPTS_CHART",
    ZIPCAM_DAILY_PUSH_ATTEMPTS_CHART = "ZIPCAM_DAILY_PUSH_ATTEMPTS_CHART",
    ZIPCAM_GEOLOCATION_CHART = "ZIPCAM_GEOLOCATION_CHART",
    ZIPCAM_RECENT_EXCEPTION_CHART = "ZIPCAM_RECENT_EXCEPTION_CHART",
    OBJECTIVES = "OBJECTIVES",
    PULL_REQUESTS = "PULL_REQUESTS",
    SPRINTS = "SPRINTS",
    VERSIONS = "VERSIONS",
}
