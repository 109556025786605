import { Box, styled } from "@mui/material";
import { useEmbedMongoChart } from "./hooks/useEmbedMongoChart";

const ChartRef = styled("div")({
    height: "100%",
    width: "100%",
});

interface Props {
    baseUrl: string;
    chartId: string;
}

export function MongoChart({ baseUrl, chartId }: Props) {
    const { chartDiv } = useEmbedMongoChart(baseUrl, chartId);

    return (
        <Box sx={{ height: "100%" }}>
            <ChartRef ref={chartDiv} />;
        </Box>
    );
}
