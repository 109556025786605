import moment from "moment";
import { PullRequest } from "../domain/PullRequest";
import { Link, styled, Typography } from "@mui/material";
import { Cancel, CheckCircle, WatchLater } from "@mui/icons-material";

interface Props {
    pullRequest: PullRequest;
}

const PullRequestDescriptionContainer = styled("span")(({ theme }) => ({
    display: "flex",
    alignItem: "center",
    gap: theme.spacing(1),
}));

const Reviewer = styled("span")({
    display: "flex",
    alignItem: "center",
    gap: 4,
});

export function PullRequestDescription({ pullRequest }: Props) {
    const prDate = moment(pullRequest.creationDate);
    const prDateFormat = prDate < moment().startOf("isoWeek") ? "D/M/YYYY" : "ddd, hA";

    const sourceBranch = pullRequest.sourceRefName.split("/").slice(-1)[0];
    const targetBranch = pullRequest.targetRefName.split("/").slice(-1)[0];

    const reviewers = pullRequest.reviewers.filter(
        (reviewer) => !reviewer.displayName.toLowerCase().includes("reviewers")
    );

    return (
        <PullRequestDescriptionContainer>
            <Typography variant="subtitle2" component="span">
                {prDate.format(prDateFormat)}
            </Typography>
            -
            <Typography variant="subtitle2" component="span">
                {pullRequest.createdBy.displayName}
            </Typography>
            -
            <Link
                variant="subtitle2"
                href={`https://dev.azure.com/xpectio/SewerInspection/_git/pipe-inspection?version=GB${sourceBranch}`}
                target="_blank"
            >
                {sourceBranch}
            </Link>
            into
            <Link
                variant="subtitle2"
                href={`https://dev.azure.com/xpectio/SewerInspection/_git/pipe-inspection?version=GB${targetBranch}`}
                target="_blank"
            >
                {targetBranch}
            </Link>
            {reviewers.map(({ displayName, vote }) => (
                <Reviewer key={displayName}>
                    {vote >= 5 && <CheckCircle fontSize="small" sx={{ color: "#9fe507" }} />}
                    {vote === -5 && <WatchLater fontSize="small" sx={{ color: "warning.main" }} />}
                    {vote === -10 && <Cancel fontSize="small" sx={{ color: "error.main" }} />}

                    <span>{displayName}</span>
                </Reviewer>
            ))}
        </PullRequestDescriptionContainer>
    );
}
