import axios, { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import * as config from "../../config";
import { BuildEvent, buildEventFromApiE2e } from "../../Interfaces";

const E2E_EVENTS_QUERY_KEY = "E2E_EVENTS_QUERY_KEY";

export function useE2eEvents() {
    const { data: e2eEvents, isLoading } = useQuery<BuildEvent[], AxiosError>({
        queryKey: E2E_EVENTS_QUERY_KEY,
        queryFn: getE2eEventsQueryFunction,
        onError: (error) => {
            alert(`Damn... an error fetching E2eEvents ${error}`);
            return [];
        },
    });

    return useMemo(() => ({ e2eEvents: e2eEvents || [], isLoading }), [e2eEvents, isLoading]);
}

const getE2eEventsQueryFunction = () =>
    axios
        .get(`${config.API_BASE_URL}/e2e`, {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        })
        .then((response: AxiosResponse<any[]>) => response.data.map(buildEventFromApiE2e));
