import { Box, Card, CardContent, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import moment from "moment";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useBuilds } from "../hooks/useBuilds";
import { useE2eEvents } from "../hooks/useE2eEvents";
import { useGitTags } from "../hooks/useGitTags";

const classes: Record<string, SxProps<Theme>> = {
    container: {
        display: "flex",
        maxWidth: "100%",
    },
    calendar: {
        width: "100%",
        minWidth: "400px",
        margin: "5px",
    },
    h2: {
        marginTop: 0,
        color: "white",
    },
};

const localizer = momentLocalizer(moment);

export function Calendar() {
    const { gitTags } = useGitTags();
    const { builds } = useBuilds();
    const { e2eEvents } = useE2eEvents();

    return (
        <div>
            <Box sx={classes.container}>
                <Card variant="outlined" sx={classes.calendar}>
                    <CardContent>
                        <BigCalendar
                            localizer={localizer}
                            defaultDate={new Date()}
                            defaultView="month"
                            events={[...e2eEvents, ...builds, ...gitTags]}
                            onSelectEvent={(event) => {
                                console.log(event);
                                event.url && window.open(event.url);
                            }}
                            style={{ height: "85vh" }}
                            eventPropGetter={(event, start, end, isSelected) => {
                                let newStyle = {
                                    backgroundColor: event.color || "lightgrey",
                                    color: "black",
                                    borderRadius: "0px",
                                    border: "none",
                                };

                                return {
                                    className: "",
                                    style: newStyle,
                                };
                            }}
                        />
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
}
