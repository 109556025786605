import axios from "axios";
import { PATHS } from "./core/navigation/path";

export function setupAxios() {
    axios.interceptors.response.use(
        (response) => {
            return Promise.resolve(response);
        },
        (error) => {
            if (error != null && error.response.status === 401) {
                window.location.href = PATHS.login;
            }
            return Promise.reject(error);
        }
    );
}
