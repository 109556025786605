export const NAVIGATION_DRAWER_PX_WITH = 55;

export const PRODUCTION_URL = "https://analysis.canex.tech";
export const STAGING_URL = "https://analysis-staging.canex.live";
export const DEVELOPMENT_URL = "https://analysis-development.canex.live";

export const AZURE_ROOT_URL = "https://dev.azure.com/xpectio/SewerInspection/";
export const AZURE_BACKLOG_URL = `${AZURE_ROOT_URL}_backlogs/backlog/SewerInspection%20Team/Stories`;
export const AZURE_ESTIMATE_URL = `${AZURE_ROOT_URL}_apps/hub/ms-devlabs.estimate.estimate-hub#/`;
export const AZURE_RETRO_URL = `${AZURE_ROOT_URL}_apps/hub/ms-devlabs.team-retrospectives.home`;

export const GCP_ROOT_URL = "https://console.cloud.google.com/";
export const GCP_LOGGING_PROD_URL = `${GCP_ROOT_URL}logs/query;cursorTimestamp=2022-04-28T20:25:55.555590Z?referrer=search&authuser=1&project=xpectio-prod`;
export const GCP_BUCKETS_PROD_URL = `${GCP_ROOT_URL}storage/browser?authuser=1&project=xpectio-prod&prefix=`;
export const GCP_BUCKETS_DEV_STAGING_URL = `${GCP_ROOT_URL}storage/browser?authuser=1&project=xpectio-dev-staging&prefix=`;
export const GOOGLE_ANALYTICS_URL =
    "https://analytics.google.com/analytics/web/?authuser=2#/report/content-event-events/a201099660w277802197p246118031/explorer-table.plotKeys=%5B%5D&_r.drilldown=analytics.eventCategory:Hotkeys%20Actions/";

export const SENTRY_URL = "https://sentry.io/organizations/can-explore-54/issues/?project=1399592";
