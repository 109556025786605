import { MongoChart } from "../core/mongo/MongoChart";

export function ZipCamTotalPushAttemptsChart() {
    return (
        <MongoChart
            baseUrl="https://charts.mongodb.com/charts-canex-prod-staging-dev-mndwt"
            chartId="b26c53b9-4880-4812-a86f-867edbbf3ea2"
        />
    );
}
