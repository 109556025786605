import { MongoChart } from "../core/mongo/MongoChart";

export function FieldRecentExceptionChart() {
    return (
        <MongoChart
            baseUrl="https://charts.mongodb.com/charts-canex-prod-staging-dev-mndwt"
            chartId="8467796a-ba63-4693-99aa-234e5ce3371f"
        />
    );
}
