import { MongoChart } from "../core/mongo/MongoChart";

export function FieldUserChart() {
    return (
        <MongoChart
            baseUrl="https://charts.mongodb.com/charts-canex-prod-staging-dev-mndwt"
            chartId="1d784f8b-b157-41ad-a403-2a4732b7a158"
        />
    );
}
