import axios, { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import * as config from "../../config";
import { BuildEvent, eventFromApiGitTag } from "../../Interfaces";

const GIT_TAGS_QUERY_KEY = "GIT_TAGS_QUERY_KEY";

export function useGitTags() {
    const { data: gitTags, isLoading } = useQuery<BuildEvent[], AxiosError>({
        queryKey: GIT_TAGS_QUERY_KEY,
        queryFn: getBuildsQueryFunction,
        onError: (error) => {
            alert(`Damn... an error fetching GitTags: ${error}`);
            return [];
        },
    });

    return useMemo(() => ({ gitTags: gitTags || [], isLoading }), [gitTags, isLoading]);
}

const getBuildsQueryFunction = () =>
    axios
        .get(`${config.API_BASE_URL}/git-tags`, {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        })
        .then((response: AxiosResponse<any[]>) => response.data.map(eventFromApiGitTag));
