import { Box, IconButton, Step, StepContent, StepLabel, Stepper, styled, Typography } from "@mui/material";
import { useState } from "react";
import { ApplicationsDeploymentTag } from "../domain/ApplicationsDeploymentTag";
import { DeploymentEnvironments } from "../domain/DeploymentEnvironments";
import { useApplicationsVersion } from "../hooks/useApplicationsVersion";
import { deployProduction, deployStaging } from "../services/deployApplications";
import { ApplicationsSelectionStep } from "./ApplicationsSelectionStep";
import { EnvironmentSelectionStep } from "./EnvironmentSelectionStep";
import { ReviewDeploymentStep } from "./ReviewDeploymentStep";
import { LocalShippingOutlined, OpenInNew } from "@mui/icons-material";

const TileTitle = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
}));

export function NewDeploymentStepper() {
    const [activeStep, setActiveStep] = useState<number>(0);
    const [environment, setEnvironment] = useState<DeploymentEnvironments | null>(null);
    const [selectedApplications, setSelectedApplications] = useState<ApplicationsDeploymentTag[]>([]);
    const applicationVersions = useApplicationsVersion();

    const openDeploymentApprovalsInNewWindow = (pullRequestId?: string) => {
        window.open("https://dev.azure.com/xpectio/SewerInspection/_build");
    };

    return (
        <>
            <TileTitle>
                <LocalShippingOutlined fontSize="large" />
                <Typography variant="h5">Deployment</Typography>
                <IconButton
                    size="small"
                    sx={{ color: "grey.600" }}
                    onClick={() => openDeploymentApprovalsInNewWindow()}
                >
                    <OpenInNew />
                </IconButton>
            </TileTitle>
            <Box sx={{ display: "flex", flexDirection: "column", flex: 1, padding: 1, overflow: "auto" }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    <Step>
                        <StepLabel>{environment ? `${environment} environment` : "Select environment"}</StepLabel>
                        <StepContent>
                            <EnvironmentSelectionStep
                                onSelectEnvironment={(environment) => {
                                    setEnvironment(environment);
                                    setActiveStep(1);
                                }}
                            />
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>Select Applications and Services</StepLabel>
                        <StepContent>
                            <ApplicationsSelectionStep
                                applications={applicationVersions.staging}
                                onReview={(applicationsToReview) => {
                                    setSelectedApplications(applicationsToReview);
                                    setActiveStep(2);
                                }}
                            />
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>Review Tags</StepLabel>
                        <StepContent>
                            <ReviewDeploymentStep
                                selectedApplications={selectedApplications}
                                environment={environment!}
                                onDeploy={() =>
                                    environment === DeploymentEnvironments.STAGING
                                        ? deployStaging(selectedApplications)
                                        : deployProduction(selectedApplications)
                                }
                            />
                        </StepContent>
                    </Step>
                </Stepper>
            </Box>
        </>
    );
}
