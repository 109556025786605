import { usePullRequestsQuery } from "../hooks/usePullRequestsQuery";
import { PullRequest } from "../domain/PullRequest";
import { LoadingSpinner } from "../../utils/components/LoadingSpinner";
import {
    Avatar,
    Badge,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    styled,
    Tooltip,
    Typography,
} from "@mui/material";
import { MergeType, OpenInNew } from "@mui/icons-material";
import { PullRequestDescription } from "./PullRequestDescription";

const ListContainer = styled("div")(() => ({
    overflow: "auto",
}));

const TileTitle = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
}));

export function PullRequestsTile() {
    const { isLoading, data: pullRequests = [] } = usePullRequestsQuery();

    const openPullRequestInNewWindow = (repo: string, pullRequestId: string = "") => {
        window.open(`https://dev.azure.com/xpectio/SewerInspection/_git/${repo}/pullrequest/${pullRequestId}`);
    };

    return (
        <>
            <TileTitle>
                <MergeType fontSize="large" />
                <Typography variant="h5">Pull Requests</Typography>

                <Tooltip placement="bottom" arrow title={"pipe-inspection"}>
                    <Badge
                        badgeContent={"P"}
                        color="primary"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                    >
                        <IconButton
                            size="small"
                            sx={{ color: "grey.600" }}
                            onClick={() => openPullRequestInNewWindow("pipe-inspection")}
                        >
                            <OpenInNew />
                        </IconButton>
                    </Badge>
                </Tooltip>

                <Tooltip placement="bottom" arrow title={"k8s-stuff"}>
                    <Badge
                        badgeContent={"K"}
                        color="secondary"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                    >
                        <IconButton
                            size="small"
                            sx={{ color: "grey.600" }}
                            onClick={() => openPullRequestInNewWindow("k8s-stuff")}
                        >
                            <OpenInNew />
                        </IconButton>
                    </Badge>
                </Tooltip>
            </TileTitle>
            {isLoading && <LoadingSpinner />}
            {!isLoading && pullRequests.length === 0 && <div>All done congrats!</div>}
            {!isLoading && pullRequests.length > 0 && (
                <ListContainer>
                    <List>
                        {pullRequests
                            .filter((pullRequest: PullRequest) => !pullRequest.isDraft)
                            .map((pullRequest: PullRequest) => (
                                <ListItem key={pullRequest.pullRequestId}>
                                    <ListItemAvatar>
                                        <Badge
                                            badgeContent={pullRequest.repository.name === "pipe-inspection" ? "P" : "K"}
                                            color={
                                                pullRequest.repository.name === "pipe-inspection"
                                                    ? "primary"
                                                    : "secondary"
                                            }
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "right",
                                            }}
                                        >
                                            <Avatar>
                                                <IconButton
                                                    size="small"
                                                    onClick={() =>
                                                        openPullRequestInNewWindow(
                                                            pullRequest.repository.name,
                                                            pullRequest.pullRequestId
                                                        )
                                                    }
                                                >
                                                    <OpenInNew />
                                                </IconButton>
                                            </Avatar>
                                        </Badge>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant="h6" component="p">
                                                {pullRequest.title}
                                            </Typography>
                                        }
                                        secondary={<PullRequestDescription pullRequest={pullRequest} />}
                                    />
                                </ListItem>
                            ))}
                    </List>
                </ListContainer>
            )}
        </>
    );
}
