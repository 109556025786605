import { MongoChart } from "../core/mongo/MongoChart";

export function FieldTotalSyncAttemptsChart() {
    return (
        <MongoChart
            baseUrl="https://charts.mongodb.com/charts-canex-prod-staging-dev-mndwt"
            chartId="78fc57d2-9987-4d01-9917-4da95184d036"
        />
    );
}
