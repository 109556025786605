import { MosaicParent } from "react-mosaic-component";
import { MosaicDashboard } from "../dashboard/domain/components/MosaicDashboard";
import { Tile } from "../dashboard/domain/Tile";

export const FIELD_DASHBOARD_ID = "FIELD_DASHBOARD_ID";

const INITIAL_DASHBOARD_STATE: MosaicParent<Tile> = {
    direction: "row",
    splitPercentage: 65,
    first: {
        direction: "column",
        splitPercentage: 50,
        first: {
            direction: "column",
            splitPercentage: 50,
            first: Tile.FIELD_TOTAL_SYNC_ATTEMPTS_CHART,
            second: Tile.FIELD_DAILY_SYNC_ATTEMPTS_CHART,
        },
        second: Tile.FIELD_STATUS_PER_VERSION_CHART,
    },
    second: {
        direction: "column",
        splitPercentage: 50,
        first: Tile.FIELD_USER_CHART,
        second: Tile.FIELD_RECENT_EXCEPTION_CHART,
    },
};

export function FieldDashboard() {
    return <MosaicDashboard dashboardId={FIELD_DASHBOARD_ID} initialDashboardState={INITIAL_DASHBOARD_STATE} />;
}
