import moment from "moment";

export interface BuildEventApi {
    title: string;
    buildNumber: string;
    start: string;
    end: string;
    isSuccess?: boolean;
    color?: string;
    name?: string;
    url?: string;
}

export interface BuildEvent {
    title: string;
    start: Date;
    end: Date;
    color?: string;
    url?: string;
}

export function buildEventFromApiE2e(apiBuildEvent: BuildEventApi): BuildEvent {
    const start = new Date(Date.parse(apiBuildEvent.start));
    const end = new Date(Date.parse(apiBuildEvent.end));
    const startTime = moment(start).format("h:mm");

    return {
        title: `${apiBuildEvent?.title} (${startTime})`,
        start: start,
        end: end,
        color: apiBuildEvent?.isSuccess ? "#44bf44" : "#DF3C00",
        url: apiBuildEvent?.url,
    };
}

export function buildEventFromApiBuild(apiBuildEvent: BuildEventApi): BuildEvent {
    const start = new Date(Date.parse(apiBuildEvent.start));
    const end = new Date(Date.parse(apiBuildEvent.end));

    return {
        title: apiBuildEvent?.name || "oops",
        start: start,
        end: end,
        color: "lightgrey",
        url: apiBuildEvent?.url,
    };
}

export function eventFromApiGitTag(apiGitTag: any) {
    const start = new Date(Date.parse(apiGitTag.date));
    const end = new Date(Date.parse(apiGitTag.date));

    return {
        title: apiGitTag.name,
        start: start,
        end: end,
        color: apiGitTag.name.endsWith("-rc") ? "#3333ff" : "#03a0ff",
        url: `https://dev.azure.com/xpectio/SewerInspection/_git/pipe-inspection?version=GT${apiGitTag.name.replace(
            "/",
            "%2F"
        )}`,
    };
}
