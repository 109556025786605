import { useCallback } from "react";
import { SpeedDial, SpeedDialAction, SpeedDialIcon, Theme } from "@mui/material";
import { MosaicParent } from "react-mosaic-component";
import { Tile } from "../Tile";
import { AddOutlined } from "@mui/icons-material";
import { SxProps } from "@mui/system";

const classes: Record<string, SxProps<Theme>> = {
    speedDial: {
        position: "absolute",
        bottom: 16,
        right: 16,
    },
};

interface Props {
    tilesTree: MosaicParent<Tile>;
    setTilesTree: (_: (previousTilesTree: MosaicParent<Tile>) => MosaicParent<Tile>) => void;
}

export function AddTilesDial({ tilesTree, setTilesTree }: Props) {
    const addTile = useCallback(
        (tile: Tile) => {
            setTilesTree((previousTilesTree: MosaicParent<Tile>) => ({
                direction: "row",
                first: tile,
                splitPercentage: 50,
                second: { ...previousTilesTree },
            }));
        },
        [setTilesTree]
    );

    const getAvailableTiles = useCallback((): Tile[] => {
        const usedTiles = getUsedTiles(tilesTree);
        return Object.keys(Tile).filter((tile) => !usedTiles.includes(tile as Tile)) as Tile[];
    }, [tilesTree]);

    return (
        <SpeedDial ariaLabel="add tile" sx={classes.speedDial} icon={<SpeedDialIcon />} FabProps={{ size: "small" }}>
            {getAvailableTiles().map((tile: Tile) => (
                <SpeedDialAction
                    key={tile}
                    icon={<AddOutlined />}
                    tooltipTitle={tile}
                    tooltipOpen
                    onClick={() => addTile(tile)}
                />
            ))}
        </SpeedDial>
    );
}

const getUsedTiles = ({ first, second }: MosaicParent<Tile>): Tile[] => {
    const firstTiles = typeof first === "object" ? getUsedTiles(first) : [first];
    const secondTiles = typeof second === "object" ? getUsedTiles(second) : [second];

    return [...firstTiles, ...secondTiles];
};
