import { MongoChart } from "../core/mongo/MongoChart";

export function ZipCamDailyPushAttemptsChart() {
    return (
        <MongoChart
            baseUrl="https://charts.mongodb.com/charts-canex-prod-staging-dev-mndwt"
            chartId="13308997-0055-433d-942f-50015ff5dd48"
        />
    );
}
