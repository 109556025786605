import { useQuery } from "react-query";
import axios, { AxiosResponse } from "axios";
import { API_BASE_URL } from "../../config";
import { PullRequest } from "../domain/PullRequest";

export const usePullRequestsQuery = () => {
    return useQuery<PullRequest[]>("pullRequests", async () =>
        axios
            .get(`${API_BASE_URL}/pull-requests`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
            })
            .then(({ data }: AxiosResponse<PullRequest[]>) => data)
    );
};
