import { Chip, Stack, styled, Typography, useTheme } from "@mui/material";

const ListContainer = styled("div")(({ theme }) => ({
    overflow: "auto",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
}));

const TileTitle = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
}));

export function Objectives() {
    const { spacing } = useTheme();

    return (
        <>
            <TileTitle>
                <Typography variant="h5">Main Objectives</Typography>
            </TileTitle>

            <ListContainer>
                <Stack direction="row" spacing={spacing(1)}>
                    <Chip label="1. ZIPCAM" variant="outlined" />
                    <Chip label="2. ZIPCAM" variant="outlined" />
                    <Chip label="3. ZIPCAM" variant="outlined" />
                    <Chip label="4. ZIPCAM" variant="outlined" />
                </Stack>
            </ListContainer>
        </>
    );
}
