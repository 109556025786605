import { Box } from "@mui/material";
import { PrimaryButton } from "../../core/components/PrimaryButton";
import { DeploymentEnvironments } from "../domain/DeploymentEnvironments";

interface Props {
    onSelectEnvironment: (environment: DeploymentEnvironments) => void;
}

export function EnvironmentSelectionStep({ onSelectEnvironment }: Props) {
    return (
        <Box
            sx={{
                display: "flex",
                gap: 2,
            }}
            data-testid="step.selectEnvironment"
        >
            <PrimaryButton
                onClick={() => onSelectEnvironment(DeploymentEnvironments.STAGING)}
                data-testid="button.staging"
            >
                Staging
            </PrimaryButton>
            <PrimaryButton
                onClick={() => onSelectEnvironment(DeploymentEnvironments.PRODUCTION)}
                data-testid="button.production"
            >
                Production
            </PrimaryButton>
        </Box>
    );
}
