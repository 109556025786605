import axios, { AxiosError, AxiosResponse } from "axios";
import * as config from "../../config";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { BuildEvent, buildEventFromApiBuild } from "../../Interfaces";

const BUILDS_QUERY_KEY = "BUILDS_QUERY_KEY";

export function useBuilds() {
    const { data: builds, isLoading } = useQuery<BuildEvent[], AxiosError>({
        queryKey: BUILDS_QUERY_KEY,
        queryFn: getBuildsQueryFunction,
        onError: (error) => {
            alert(`Damn... an error fetching Builds ${error}`);
            return [];
        },
    });

    return useMemo(() => ({ builds: builds || [], isLoading }), [builds, isLoading]);
}

const getBuildsQueryFunction = () =>
    axios
        .get(`${config.API_BASE_URL}/builds`, {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        })
        .then((response: AxiosResponse<any[]>) => response.data.map(buildEventFromApiBuild));
