import AnalyticsIcon from "@mui/icons-material/Analytics";
import HomeIcon from "@mui/icons-material/Home";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { RocketLaunch } from "@mui/icons-material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import GoogleIcon from "@mui/icons-material/Google";
import StorageIcon from "@mui/icons-material/Storage";
import StyleIcon from "@mui/icons-material/Style";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../core/navigation/path";
import {
    AZURE_BACKLOG_URL,
    AZURE_ESTIMATE_URL,
    AZURE_RETRO_URL,
    GCP_BUCKETS_DEV_STAGING_URL,
    GCP_BUCKETS_PROD_URL,
    DEVELOPMENT_URL,
    GOOGLE_ANALYTICS_URL,
    GCP_LOGGING_PROD_URL,
    NAVIGATION_DRAWER_PX_WITH,
    PRODUCTION_URL,
    SENTRY_URL,
    STAGING_URL,
} from "./constants";
import { useMe } from "./hooks/useMe";

const useStyles = makeStyles({
    drawerContent: {
        width: NAVIGATION_DRAWER_PX_WITH,
    },
});

const DrawerItem = styled("div")(() => ({
    display: "flex",
    justifyContent: "center",
}));

function openTab(url: string) {
    return () => window.open(url);
}

export default function NavigationDrawer() {
    const classes = useStyles();
    const navigate = useNavigate();

    useMe();

    return (
        <MuiDrawer classes={{ paper: classes.drawerContent }} variant="permanent">
            <DrawerItem>
                <Tooltip title="Logout" placement="right">
                    <IconButton
                        onClick={() => {
                            localStorage.removeItem("accessToken")
                            navigate(PATHS.login)
                        }}
                    >
                        <Avatar>C</Avatar>
                    </IconButton>
                </Tooltip>
            </DrawerItem>
            <DrawerItem>
                <Tooltip title="Main Dashboard" placement="right">
                    <IconButton
                        onClick={() => {
                            navigate(PATHS.dashboard);
                        }}
                    >
                        <HomeIcon fontSize="large" />
                    </IconButton>
                </Tooltip>
            </DrawerItem>
            <DrawerItem>
                <Tooltip title="Field Dashboard" placement="right">
                    <IconButton
                        onClick={() => {
                            navigate(PATHS.fieldDashboard);
                        }}
                    >
                        <DashboardIcon fontSize="large" />
                    </IconButton>
                </Tooltip>
            </DrawerItem>
            <DrawerItem>
                <Tooltip title="ZipCam Dashboard" placement="right">
                    <IconButton
                        onClick={() => {
                            navigate(PATHS.zipcamDashboard);
                        }}
                    >
                        <RocketLaunch fontSize="large" />
                    </IconButton>
                </Tooltip>
            </DrawerItem>
            <Divider />

            <DrawerItem>
                <Tooltip title="Analysis Production" placement="right">
                    <IconButton onClick={openTab(PRODUCTION_URL)}>
                        <Avatar>P</Avatar>
                    </IconButton>
                </Tooltip>
            </DrawerItem>
            <DrawerItem>
                <Tooltip title="Analysis Staging" placement="right">
                    <IconButton onClick={openTab(STAGING_URL)}>
                        <Avatar>S</Avatar>
                    </IconButton>
                </Tooltip>
            </DrawerItem>
            <DrawerItem>
                <Tooltip title="Analysis Development" placement="right">
                    <IconButton onClick={openTab(DEVELOPMENT_URL)}>
                        <Avatar>D</Avatar>
                    </IconButton>
                </Tooltip>
            </DrawerItem>

            <Divider />

            <DrawerItem>
                <Tooltip title="Azure Backlog" placement="right">
                    <IconButton onClick={openTab(AZURE_BACKLOG_URL)}>
                        <AssignmentIcon fontSize="large" />
                    </IconButton>
                </Tooltip>
            </DrawerItem>
            <DrawerItem>
                <Tooltip title="Azure Estimate" placement="right">
                    <IconButton onClick={openTab(AZURE_ESTIMATE_URL)}>
                        <StyleIcon fontSize="large" />
                    </IconButton>
                </Tooltip>
            </DrawerItem>
            <DrawerItem>
                <Tooltip title="Azure Retrospective" placement="right">
                    <IconButton onClick={openTab(AZURE_RETRO_URL)}>
                        <FastRewindIcon fontSize="large" />
                    </IconButton>
                </Tooltip>
            </DrawerItem>

            <Divider />

            <DrawerItem>
                <Tooltip title="GCP Logging" placement="right">
                    <IconButton onClick={openTab(GCP_LOGGING_PROD_URL)}>
                        <GoogleIcon fontSize="large" />
                    </IconButton>
                </Tooltip>
            </DrawerItem>
            <DrawerItem>
                <Tooltip title="GCP Production Buckets" placement="right">
                    <IconButton onClick={openTab(GCP_BUCKETS_PROD_URL)}>
                        <StorageIcon fontSize="large" />
                    </IconButton>
                </Tooltip>
            </DrawerItem>
            <DrawerItem>
                <Tooltip title="GCP Dev/Staging Buckets" placement="right">
                    <IconButton onClick={openTab(GCP_BUCKETS_DEV_STAGING_URL)}>
                        <StorageIcon fontSize="large" />
                    </IconButton>
                </Tooltip>
            </DrawerItem>
            <DrawerItem>
                <Tooltip title="Google Analytics" placement="right">
                    <IconButton onClick={openTab(GOOGLE_ANALYTICS_URL)}>
                        <AnalyticsIcon fontSize="large" />
                    </IconButton>
                </Tooltip>
            </DrawerItem>

            <Divider />

            <DrawerItem>
                <Tooltip title="Sentry" placement="right">
                    <IconButton onClick={openTab(SENTRY_URL)}>
                        <Avatar>S</Avatar>
                    </IconButton>
                </Tooltip>
            </DrawerItem>
        </MuiDrawer>
    );
}
