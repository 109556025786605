import { createTheme, CssBaseline, Theme, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";
import { AppRouter } from "./core/navigation/AppRouter";

export const darkTheme: Theme = createTheme({
    palette: {
        mode: "dark",
        primary: { light: "#64b5f6", main: "#1976d2", dark: "#1265b7" },
        background: {
            paper: "#212121",
        },
    },
});

const queryClient = new QueryClient();

export function App() {
    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <QueryClientProvider client={queryClient}>
                <AppRouter />
            </QueryClientProvider>
        </ThemeProvider>
    );
}
