import axios from "axios";
import * as config from "../../config";
import { useMemo } from "react";
import { useQuery } from "react-query";

export function useMe() {
    const { data, isLoading } = useQuery(["me"], () => axios
        .get<{ username: string }>(`${config.API_BASE_URL}/me`, {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }));

    return useMemo(() => ({ data, isLoading }), [isLoading, data]);
}
