import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { NAVIGATION_DRAWER_PX_WITH } from "../navigation/constants";
import NavigationDrawer from "../navigation/NavigationDrawer";

export function AppContainer() {
    return (
        <>
            <NavigationDrawer />

            <Box sx={{ paddingLeft: `${NAVIGATION_DRAWER_PX_WITH}px` }}>
                <Outlet />
            </Box>
        </>
    );
}
