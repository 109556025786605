import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { useEffect, useMemo, useRef, useState } from "react";

export function useEmbedMongoChart(baseUrl: string, chartId: string) {
    const sdk = new ChartsEmbedSDK({
        baseUrl,
        getUserToken,
    });

    const chartDiv = useRef(null);

    const [chart] = useState(sdk.createChart({ chartId, theme: "dark" }));

    useEffect(() => {
        chartDiv && chartDiv.current && chart.render(chartDiv.current!);
    }, [chart]);

    return useMemo(
        () => ({
            chartDiv,
        }),
        []
    );
}

function getUserToken(): string {
    const token = localStorage.getItem("accessToken");

    if (!token) throw new Error("Not token");

    return token;
}
