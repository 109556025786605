import { MosaicParent } from "react-mosaic-component";
import { MosaicDashboard } from "../dashboard/domain/components/MosaicDashboard";
import { Tile } from "../dashboard/domain/Tile";

export const ZIPCAM_DASHBOARD_ID = "ZIPCAM_DASHBOARD_ID";

const INITIAL_DASHBOARD_STATE: MosaicParent<Tile> = {
    direction: "column",
    splitPercentage: 40,
    first: {
        direction: "row",
        splitPercentage: 50,
        first: Tile.ZIPCAM_TOTAL_PUSH_ATTEMPTS_CHART,
        second: Tile.ZIPCAM_DAILY_PUSH_ATTEMPTS_CHART,
    },
    second: {
        direction: "row",
        splitPercentage: 65,
        first: Tile.ZIPCAM_GEOLOCATION_CHART,
        second: Tile.ZIPCAM_RECENT_EXCEPTION_CHART,
    }
};

export function ZipCamDashboard() {
    return <MosaicDashboard dashboardId={ZIPCAM_DASHBOARD_ID} initialDashboardState={INITIAL_DASHBOARD_STATE} />;
}
