import { Checkbox, FormControlLabel } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { PrimaryButton } from "../../core/components/PrimaryButton";
import { ApplicationsDeploymentTag } from "../domain/ApplicationsDeploymentTag";

interface Props {
    applications: ApplicationsDeploymentTag[];
    onReview: (applicationsToReview: ApplicationsDeploymentTag[]) => void;
}

export function ApplicationsSelectionStep({ applications, onReview }: Props) {
    const [selectedApplications, setSelectedApplications] = useState<ApplicationsDeploymentTag[]>([]);

    const isSelected = (application: ApplicationsDeploymentTag) =>
        selectedApplications.some((a) => a.name === application.name);

    const select = (application: ApplicationsDeploymentTag) =>
        setSelectedApplications((applications) => [...applications, application]);

    const unselect = (application: ApplicationsDeploymentTag) =>
        setSelectedApplications((applications) => applications.filter((a) => a.name !== application.name));

    const handleSelectionChangeOf =
        (application: ApplicationsDeploymentTag) =>
        ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) =>
            checked ? select(application) : unselect(application);

    return (
        <Box
            data-testid="step.selectApplications"
            sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", flex: 1 }}
        >
            {applications.map((application) => (
                <FormControlLabel
                    key={application.name}
                    control={
                        <Checkbox
                            checked={isSelected(application)}
                            inputProps={{ "data-testid": `checkbox.${application.name}` } as object}
                            onChange={handleSelectionChangeOf(application)}
                        />
                    }
                    label={`${application.name} (${application.version})`}
                />
            ))}
            <PrimaryButton
                data-testid="button.review"
                onClick={() => onReview(selectedApplications)}
                disabled={selectedApplications.length === 0}
                sx={{ marginTop: 2 }}
            >
                Review
            </PrimaryButton>
        </Box>
    );
}
