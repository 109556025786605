import { MosaicParent } from "react-mosaic-component/src/types";
import { Tile } from "../domain/Tile";
import { MosaicDashboard } from "../domain/components/MosaicDashboard";

export const MAIN_DASHBOARD_ID = "MAIN_DASHBOARD_ID";

const INITIAL_DASHBOARD_STATE: MosaicParent<Tile> = {
    direction: "row",
    splitPercentage: 50,
    first: {
        direction: "column", splitPercentage: 50,
        first: Tile.ZIPCAM_DAILY_PUSH_ATTEMPTS_CHART, second: Tile.FIELD_DAILY_SYNC_ATTEMPTS_CHART
    },
    second: {
        direction: "column", splitPercentage: 10,
        first: Tile.OBJECTIVES, second: Tile.CALENDAR
    },
};

export function Dashboard() {
    return (
        <MosaicDashboard
            dashboardId={MAIN_DASHBOARD_ID}
            initialDashboardState={INITIAL_DASHBOARD_STATE}
            showAddTilesDial={true}
        />
    );
}
