import { OpenWithOutlined } from "@mui/icons-material";
import { Box, styled } from "@mui/material";
import { ReactElement, useCallback, useState } from "react";
import {
    Mosaic,
    MosaicBranch,
    MosaicNode,
    MosaicParent,
    MosaicWindow,
    MosaicWindowProps,
} from "react-mosaic-component";
import "react-mosaic-component/react-mosaic-component.css";
import { Tile } from "../Tile";
import { TILE_MAP } from "../TileMap";
import { AddTilesDial } from "./AddTilesDial";

const MosaicContainer = styled("div")(({ theme }) => ({
    height: "100vh",
    ".mosaic.mosaic-blueprint-theme": {
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
        ".mosaic-window": {
            ".mosaic-window-toolbar": {
                background: theme.palette.background.paper,
                "&.draggable": {
                    position: "absolute",
                    right: 0,
                    borderRadius: 3,
                    "&:hover": {
                        background: theme.palette.action.hover,
                        ".mosaic-window-title": {
                            color: theme.palette.text.primary,
                        },
                    },
                },
                ".mosaic-window-title": {
                    color: theme.palette.text.primary,
                },
            },
            ".mosaic-window-body": {
                background: theme.palette.background.paper,
                color: theme.palette.text.primary,
                display: "flex",
                flexDirection: "column",
            },
        },
    },
    ".mosaic-preview": {
        display: "none", // hide it for now, fix it later
    },
}));

interface Props {
    dashboardId: string;
    initialDashboardState: MosaicParent<Tile>;
    showAddTilesDial?: boolean;
    children?: () => ReactElement;
}

export function MosaicDashboard({ dashboardId, initialDashboardState, showAddTilesDial, children }: Props) {
    const [tilesTree, setTilesTree] = useState<MosaicParent<Tile>>(initialDashboardState);

    const renderToolbar = (props: MosaicWindowProps<Tile>, draggable: boolean | undefined) => {
        return (
            <div>
                <Box sx={{ padding: 1, display: "flex", alignItems: "center" }}>
                    <OpenWithOutlined fontSize="small" />
                </Box>
            </div>
        );
    };

    const renderTile = (tile: Tile, path: MosaicBranch[]) => (
        <MosaicWindow<Tile> path={path} title={tile} renderToolbar={renderToolbar} toolbarControls={[]}>
            {TILE_MAP[tile]}
        </MosaicWindow>
    );

    const onTilesTreeChange = useCallback(
        (tree: MosaicNode<Tile> | null) => {
            if (tree) {
                setTilesTree(tree as MosaicParent<Tile>);
            }
        },
        [setTilesTree]
    );

    return (
        <MosaicContainer>
            <Mosaic<Tile> renderTile={renderTile} value={tilesTree} onChange={onTilesTreeChange} />
            {showAddTilesDial && <AddTilesDial tilesTree={tilesTree} setTilesTree={setTilesTree} />}
            {children?.()}
        </MosaicContainer>
    );
}
