import { MongoChart } from "../core/mongo/MongoChart";

export function ZipCamRecentExceptionChart() {
    return (
        <MongoChart
            baseUrl="https://charts.mongodb.com/charts-canex-prod-staging-dev-mndwt"
            chartId="27f9d62d-a433-42d0-b204-7b1d71cfdbb6"
        />
    );
}
