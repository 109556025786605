import { MongoChart } from "../core/mongo/MongoChart";

export function FieldStatusPerVersionChart() {
    return (
        <MongoChart
            baseUrl="https://charts.mongodb.com/charts-canex-prod-staging-dev-mndwt"
            chartId="4f6dc565-6beb-4bf1-8307-5f073ed39ab4"
        />
    );
}
