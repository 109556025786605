import { MongoChart } from "../core/mongo/MongoChart";

export function FieldDailySyncAttemptsChart() {
    return (
        <MongoChart
            baseUrl="https://charts.mongodb.com/charts-canex-prod-staging-dev-mndwt"
            chartId="72e96896-8ffd-443c-a30d-de0e40e4825e"
        />
    );
}
