import { CircularProgress, styled } from "@mui/material";

const LoadingSpinnerContainer = styled("div")({
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

export function LoadingSpinner() {
    return (
        <LoadingSpinnerContainer>
            <CircularProgress />
        </LoadingSpinnerContainer>
    );
}
