import { Button, ButtonProps } from "@mui/material";

type Props = Omit<ButtonProps, "variant" | "disableElevation" | "color">;

export function PrimaryButton(props: Props) {
    return (
        <Button {...props} variant="contained" color="primary" disableElevation>
            {props.children}
        </Button>
    );
}
